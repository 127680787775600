import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  HttpErrorHandler,
  HandleError,
} from '../../services/http-error-handler.service';
import { environment } from '../../../environments/environment';
import { DeliverableRenderingTask } from '../models/deliverables_rendering_task.model';

@Injectable({
  providedIn: 'root',
})
export class DeliverablesService {
  private apiUrl = `${environment.apiUrl}/deliverable-rendering-task`;
  private handleError: HandleError;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  redirectUrl: string;

  constructor(
    private http: HttpClient,
    private httpErrorHandler: HttpErrorHandler,
  ) {
    this.handleError =
      this.httpErrorHandler.createHandleError('ClientsService');
  }

  // for pagination
  getDeliverableTasks(offset, limit, filter?: any) {
    // console.log(JSON.stringify(filter));
    const filters = filter || filter.orderBy ? JSON.stringify(filter) : '{}';
    return (
      this.http
        .get<DeliverableRenderingTask>(
          `${this.apiUrl}/${offset}/${limit}/${filters}`,
        )
        // return this.http.get<Client>(`${this.apiUrl}/${JSON.stringify(filter)}`)
        .pipe(
          
          catchError(this.handleError('getDeliverableTasks', null)))
    );
  }

  getStats(start_date, end_date: any) {
    return (
      this.http.get(`${this.apiUrl}/getstats?startdate=${start_date}&enddate=${end_date}`,).pipe(catchError(this.handleError('getStats', null)))
    );
  }

  getHeatMap(start_date, end_date: any) {
    return (
      this.http.get(`${this.apiUrl}/getheatmap?startdate=${start_date}&enddate=${end_date}`,).pipe(catchError(this.handleError('getHeatMap', null)))
    );
  }


  DeliverableTasksExport(filter?: any) {
    const filters = filter ? JSON.stringify(filter) : '{}';
    return (
      this.http
        .get<DeliverableRenderingTask>(`${this.apiUrl}/export/${filters}`)
        // return this.http.get<Client>(`${this.apiUrl}/${JSON.stringify(filter)}`)
        .pipe(catchError(this.handleError('DeliverableTasksExport', null)))
    );
  }

  getDeliverableTask(id: number) {
    return this.http
      .get<DeliverableRenderingTask>(`${this.apiUrl}/edit/${id}`)
      .pipe(catchError(this.handleError('getDeliverableTask', null)));
  }
  changePriority(id: number) {
    return this.http
      .patch<DeliverableRenderingTask>(`${this.apiUrl}/priority/${id}`, {
        priority: -10000,
      })
      .pipe(catchError(this.handleError('getDeliverableTask', null)));
  }
  changeState(id: number, newstate: number): any {
    const params: any = { state: newstate };
    return this.http
      .patch<DeliverableRenderingTask>(`${this.apiUrl}/changestate/${id}`, params)
      .pipe(catchError(this.handleError('changeState', null)));
  }
  cancelTask(id: number, mail: string): any {
    const params: any = { taskid: id, cancelleremail: mail };
    return this.http
      .post<DeliverableRenderingTask>(`${this.apiUrl}/cancel`, params)
      .pipe(catchError(this.handleError('changeState', null)));
  }

  deleteTask(id: number): any {
    return this.http
      .delete<DeliverableRenderingTask>(`${this.apiUrl}/${id}`)
      .pipe(catchError(this.handleError('deleteTask', null)));
  }
}
